/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./assets/css/custom.css";
@import "./theme/custom-element.scss";

@import "./theme/fynka-style-variables.scss";

.safe-area {

  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  // padding-bottom: var(--safe-area-bottom-computed);

  // background:#1d8a77;
}


ion-menu {
  margin-top: env(safe-area-inset-top);
}

.btn-success,
.btn-success:hover,
.btn-success:active,
.btn-success:visited {
  background-color: #18b072 !important;
  border-color: #18b072 !important;
}

ion-input {
  --placeholder-font-style: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
  --placeholder-font-weight: 500 !important;
}

// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500;1,700;1,900&display=swap');

// ion-header {
//   padding-top: 0px !important;
// }
// .ios app-landing-header{
//   margin-top: 20px!important;
// }
// .ios ion-toolbar {
//   padding: 0 !important;
//   // margin: 0 !important;
// }

@font-face {
  font-family: "Inter";
  font-style: normal;
  // font-weight: 700;

  // font-weight: bold;
  src: url("/assets/font/Inter-VariableFont_slnt.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";

  font-style: normal;
  // font-weight: 700;

  // font-weight: bold;
  src: url("/assets/font/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

  font-style: normal;
  // font-weight: 700;

  // font-weight: bold;
  src: url("/assets/font/Poppins-Regular.ttf") format("truetype");
}

// *:not(ion-progress-bar){
// //   box-shadow:none !important;
// // }
ion-item {
  width: 100%;
}

.select-alertless {
  .alert-button-group {
    // display: none;
  }
}

.alert-button-group.sc-ion-alert-ios {
  padding: 10px !important;
  flex-wrap: nowrap !important;
  width: 92% !important;

  button {
    margin: 5px;
    border-radius: 10px !important;
  }
}

// .alert-wrapper.sc-ion-alert-ios {
//   max-width: 80%!important;
// }
.alert-button.sc-ion-alert-ios {
  min-width: none !important;
}

.popover-css-class {
  --width: 60vw;
  --min-width: 50vw;
}

.select-css-class {
  --width: 40vw;
  --min-width: 30vw;
  --height: 40vw;
  --min-height: 30vw;
}

.title-page {
  font-weight: 600;
  font-size: 22px;
}

.footer {
  background-color: #e9e9e9;
  display: flex;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 8%;
  z-index: 400;
}

.smaller-font {
  font-size: 14px;
}

.btn {
  width: 100%;
  margin-top: 3%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
  flex: 0.5;
}

//multi select css
.alert-button-inner.sc-ion-alert-ios {
  direction: rtl !important;

  .alert-checkbox-label.sc-ion-alert-ios {
    text-align: end;
    display: grid !important;
  }
}

[aria-checked="true"].sc-ion-alert-ios .alert-checkbox-icon.sc-ion-alert-ios {
  border-color: var(--ion-color-success) !important;
  background-color: var(--ion-color-success) !important;
}

.alert-head,
.alert-message {
  display: none !important;
}

button.alert-button {
  background-color: var(--ion-color-success) !important;
  color: white !important;
}

button.alert-button-role-cancel {
  background-color: var(--ion-color-danger) !important;
  color: white !important;
}

.alert-radio-group.sc-ion-alert-ios,
.alert-checkbox-group.sc-ion-alert-ios {
  max-height: 60vh !important;
}

.background-section {
  background-color: #18b072 !important;
  color: white !important;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

ion-select-popover {
  ion-list {
    height: auto !important;
    max-height: 60vh !important;
    overflow-y: auto !important;
  }
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(red, #7d1818);
  --swiper-pagination-color: var(red, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0),
      0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0),
      0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

app-moda-select.ion-page {
  display: block;
  --background: linear-gradient(158.93deg, #1b857e 0%, #65c48b 100%) !important;
}

.ion-page {
  --background: linear-gradient(158.93deg, #1b857e 0%, #65c48b 100%) !important;
}

app-footer-component {
  bottom: var(--ion-safe-area-bottom, 0) !important;
  background-color: #e8e8e899 !important;

  ion-footer {
    --offset-bottom: 0px !important;
  }
}

ion-modal {
  app-footer-component {
    background-color: transparent !important;
  }

  app-footer-dashboard {
    background-color: transparent !important;
  }
}