@import "./variables.scss";

.seperator {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

// --- Family
// gender selection ion-segment

// ion-segment-button {
//     width: 100vw;
// }

app-modal-page ion-item {
  width: 98%;
}

//######################## layout style ########################
//Modal- custom layout
.my-custom-modal-css .modal-wrapper {
  // max-height: 95%;
  //min-height: 95% !important;
  //min-width: 95% !important;
  border-radius: 30px;
  width: 100% !important;
  height: 100% !important;
}
.custom-modal-sheet .modal-wrapper {
  // max-height: 95%;
  // min-height: 45% !important;
  min-width: 100% !important;
  border-radius: 30px 30px 0px 0px !important;
  height: 40%;
  width: 100% !important;
  .sc-ion-modal-ios {
    opacity: 0.3 !important;
    background: var(--ion-color-success) !important;
    width: 100%;
  }
  .modal-shadow.sc-ion-modal-ios {
    background: var(--ion-color-success) !important;
    opacity: 0.6 !important;
    width: 100%;
  }
}
.custom-modal-sheet.height-45 .modal-wrapper {
  min-height: 45% !important;
  position: absolute !important;
  bottom: 0 !important;
}
.custom-modal-sheet.height-30 .modal-wrapper {
  min-height: 35% !important;
  ion-toolbar:first-of-type {
    --border-width: 0px 0 0;
  }
}
.custom-modal-mini .modal-wrapper {
  // max-height: 95%;
  min-height: 30% !important;
  min-width: 70% !important;
  border-radius: 12px !important;
  height: auto !important;
  width: 70% !important;
  ion-backdrop.backdrop-hide {
    background: black !important;
  }
}
.custom-modal-confirm .modal-wrapper {
  min-height: 30% !important;
  max-height: 70% !important;
  min-width: 70% !important;
  border-radius: 30px !important;
  height: 56vh !important;
  width: 85% !important;
  ion-backdrop.backdrop-hide {
    background: black !important;
  }
}
.backdrop-hide {
  background: black !important;
}
//header- add border bottom
ion-header ion-toolbar {
  --height: auto;
  border-bottom: 4px solid #8fd9ac !important;
  // --margin: 0px !important;
}

//header- remove shadow
.header-md::after {
  display: none;
}
.footer-md::before {
  display: none;
}
//header- layout header
.modal-wrapper .ion-overlay-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  // background: transparent !important;
}

ion-grid:not(
    app-login,
    ion-modal ion-grid,
    ion-popover-view ion-grid,
    ion-popover-view ion-toolbar
  ) {
  // background: linear-gradient(162deg, #fdfdfd 0%, #efefef 100%) !important;
  background: #efefef !important;
}

ion-popover-view {
  ion-grid {
    --background: transparent !important;
  }
  ion-toolbar {
    --background: transparent !important;
  }
}
// background:#fdfdfdd2!important;

app-program.ion-page {
  background-color: var(--ion-color-tertiary-tint);
}

app-card-summary-regrouped {
  background-color: black !important;
}
//header- header-title
.modal-header-title {
  width: auto;
  height: 19px;
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

//Modal- custom layout

.item-interactive.ion-valid {
  --highlight-background: transparent !important;
}

.no-borders {
  border: 0 !important;
}

.sc-ion-select-popover-h ion-list.sc-ion-select-popover,
app-popover-img {
  // overflow: auto !important;
  //background-color: var(--ion-color-secondary-tint)!important;
}

ion-item {
  width: 95% !important;
}
.popover-viewport {
  ion-grid {
    padding: 0px !important;
  }
}

.btn-w-icn {
  height: 43px;
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  /* identical to box height */
  margin: 0px;
  display: flex;
  align-items: center;
  text-transform: inherit;
  --border-radius: 7px;
  --padding: 0px;

  ion-icon {
    padding-right: 7px;
    padding-left: 7px;
    font-size: 24px;
    font-weight: 700;
  }
}
.btn-w-icn.start-label {
  text-align: start !important;
  align-items: start !important;
}
.btn-w-icn.filter {
  font-size: 16px;
  ion-icon {
    padding-right: 0px !important;
    padding-left: 0px !important;
    font-size: 21px !important;
  }
}

.card-item {
  width: 95%;
  margin: 10px auto 0px;
  background-color: var(--ion-color-tertiary);
  border: 4px solid var(--ion-color-tertiary-shade);
  border-radius: 8px;
}

ion-item {
  --highlight-height: 0px;
  --border-color: transparent !important;
}

ion-item.select-interface-option,
ion-item.border-items {
  --border-color: var(--ion-color-tertiary-shade) !important;
}
ion-item.select-interface-option:last-child {
  --border-color: transparent !important;
}

.placeholder-image {
  height: auto;
  width: 180px;
}
app-card-summary-regrouped ion-card,
app-casflow-summary ion-card,
app-summary-production-cost ion-card,
app-line-chart-expenses-chart ion-card,
app-analysis-harvest ion-card,
app-analysis-workforce ion-card,
app-analysis-workforce ion-card,
app-accounting-entry-categorie-stats ion-card,
app-lots-overview ion-card {
  gap: 40px;
  margin-inline: 2px;
  background: #ffffff;
  // border: 4px solid var(--ion-color-tertiary);
  //box-shadow: 0px 4px 0px var(--ion-color-tertiary-shade) !important;
  box-sizing: border-box !important;

  border-radius: 9px;
  ion-label {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 19px !important;
    color: var(--ion-color-dark);
  }
  ion-progress-bar {
    background: var(--ion-color-tertiary);
    border-radius: 20px;
  }
}

.success-border {
  border: 2px solid var(--ion-color-success);
  box-shadow: 0px 4px 0px var(--ion-color-success-shade) !important;
}
.danger-border {
  border: 2px solid var(--ion-color-success);
}
ion-header {
  ion-toolbar {
    padding: 0 !important;
    margin: 0 !important;
  }
}
app-programmes-filter {
  ion-toolbar {
    padding: 0 !important;
    margin: 0 !important;
  }
  // ion-button{
  //   padding: 0!important;
  //   margin: 0!important;
  //   margin-bottom: 3px!important;
  //   width: 101%!important;
  //   text-transform: capitalize;
  //   height: 60px!important;

  //   border-color: #53605aa1;
  //   border-style: solid;
  //   border-width: 1px 0.1px 0px 0.1px;

  //   border-radius: 0px!important;
  //   --border-radius: 0;

  //   gap: 1px;
  //   // box-shadow: 0px 4px 0px var(--ion-color-success-shade);
  //   text-transform: uppercase;
  //   font-family:'Poppins', sans-serif!important;
  //   font-style: normal;
  //   font-weight: 700;
  //   font-size: 14px;
  //   line-height: 21px;

  // }
}
//old footer ui
// ion-footer {
//   padding: 0 !important;
//   margin: 0 !important;
//   ion-button {
//     padding: 0 !important;
//     margin: 0 !important;
//     //margin-bottom: 3px !important;
//     width: 101% !important;
//     text-transform: capitalize;
//     height: 60px !important;

//     border-color: #53605aa1;
//     border-style: solid;
//     border-width: 1px 0.1px 0px 0.1px;

//     border-radius: 0px !important;
//     --border-radius: 0;

//     gap: 1px;
//     // box-shadow: 0px 4px 0px var(--ion-color-success-shade);
//     text-transform: uppercase;
//     font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
//     font-style: normal;
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 21px;
//   }
//   ion-toolbar {
//     padding: 0 !important;
//     margin: 0 !important;
//   }
// }

ion-footer {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  ion-button {
    --ripple-color: transparent !important;
    display: flex;
    height: 40px !important;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 10px;

    box-shadow: 0px 0px 6px 0px rgba(35, 72, 59, 0.15);
    text-transform: none !important;
    overflow: hidden;
    color: var(--Noir, #333);
    text-align: center;
    text-overflow: ellipsis;
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    //font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  // ion-toolbar {
  //   padding: 20px 20px!important;
  //   margin: 0 !important;
  // }
}

app-login {
  background: linear-gradient(158.93deg, #1b857e 0%, #65c48b 100%);
  //height: 100vh;
  width: 100%;
}

.title-analysis {
  //margin-left: 20px;
  ion-label {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--ion-title-color);
  }

  ion-icon {
    margin-top: 5px;
    font-size: 25px;
    font-weight: 700;
  }
}

.filter-title {
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
  color: var(--ion-color-tertiary-contrast);
}

.filter-year {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 4;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 5px;

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 28px;
    background: var(--ion-color-tertiary);
    border-radius: 50px;
    font-weight: bolder;
    width: 100%;
    flex: 3;
    cursor: pointer;
    height: fit-content;
    ion-label {
      font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
        "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

      font-weight: 500 !important;
      font-size: 12px;
      line-height: 14px;
      padding: 4px 20px;
      gap: 10px;
      color: var(--ion-color-tertiary-contrast);
    }
  }

  ion-icon {
    flex: 0.5;
    font-size: 24px;
    cursor: pointer;
  }
}

ion-chip.checked {
  --background: var(--ion-color-primary) !important;
  --color: var(--ion-color-light) !important;
  span {
    font-weight: 700;
  }
}

ion-chip {
  justify-content: center;
  align-items: center;
  --background: var(--ion-color-tertiary);
  --color: var(--ion-color-tertiary-contrast);
  span {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }
}

ion-text {
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 19px !important;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: var(--ion-color-tertiary-contrast);
}

ion-item.item-list,
ion-item.sub-item-list-item,
ion-item.item-of-list {
  padding-inline-start: 0px !important;
  border: 0px;
  border-radius: 0px;

  border-bottom: 1px solid var(--ion-color-tertiary-shade);
  ion-label {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 19px !important;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: var(--ion-color-tertiary-contrast);
  }
}
ion-item.sub-item-list-item {
  border: 0px;
  // background: var(--ion-color-tertiary-tint);
}

.sub-item-list {
  border: 0px;
  background: var(--ion-color-tertiary-contrast);
  border-bottom: 1px solid var(--ion-color-title);
  border-radius: 6px;
  margin-top: 5px;
  max-height: 35vh;
  min-height: 8vh;
  ion-col {
    height: -webkit-fill-available;
  }
  ion-item {
    border: 0px;
    height: -webkit-fill-available;
    display: flex;
  }
  ion-label {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    color: var(--ion-color-light) !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    display: flex;
    align-items: center;
  }
}

.sub-sub-item-list {
  border: 0px;
  border-radius: 6px;

  background: var(--ion-color-tertiary-contrast);

  border-bottom: 1px solid var(--ion-color-title);
  border-radius: 8px;
  ion-label {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    // margin-left: 32px;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    display: flex;
    align-items: center;
  }
}
.info-icon-lots-dashboard {
  position: absolute;
  z-index: 20;
  right: 15px !important;
  bottom: 10px !important;
}
.sub-item-sub-list {
  background: var(--ion-color-tertiary);
  border-radius: 6px;
  margin-top: 3px;
  border-bottom: 1px solid #a7a7a7 !important;
  max-height: 35vh;
  min-height: 8vh;
  ion-col {
    height: -webkit-fill-available;
  }
  ion-item {
    border: 0px;
    display: flex;
    height: -webkit-fill-available;
  }
  ion-label {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    color: var(--ion-title-color) !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    display: flex;
    align-items: center;
    margin: 0px;
  }
}
.info-icon-lots-dashboard {
  position: absolute;
  z-index: 20;
  right: 15px !important;
  bottom: 10px !important;
}
.sub-item-sub-list.sub {
  background: #ffffffb5 !important;
}

.product-title {
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--ion-color-tertiary-contrast);
}
.total-output-title {
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

  font-style: normal;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: var(--ion-title-color);
}

.output-title {
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: var(--ion-color-success);
  display: flex;
  align-items: center;
  text-align: right;
}

::ng-deep .mat-tab-labels {
  width: 100% !important;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--ion-color-success);
}
.swiper-pagination-bullet-active {
  background: var(--ion-color-success) !important;
}

ubdiv {
  // display: none!important;
}
ubdiv:hover {
  display: block !important;
}

.info-label {
  display: block !important;
  line-height: normal !important;
  color: var(--gris-dark, #828282) !important;
  text-align: left !important;
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  // font:  400 14px "Poppins", ui-sans-serif, system-ui, "-apple-system", "BlinkMacSystemFont", "Helvetica Neue" !important;
  position: relative !important;
  align-self: stretch !important;
  word-wrap: break-word !important;
}

.no-padding {
  padding: 0px !important;
}
