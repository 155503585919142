// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-color-toast: #000000;
  --ion-title-color: #1e2724;
  --ion-text-color: #1e2724;
  /** primary **/

  --ion-color-primary: #569AFF;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #f08080;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #aeaeae;
  --ion-color-secondary-tint: #dbdbdb;
  /** tertiary **/
  --ion-modal-bg: rgba(255, 255, 255);
  --ion-color-label: #4f4f4f;
  --ion-color-grey: #BDBDBD;
  --ion-color-tertiary: #dbdbdb;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #53605a; //for label text
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #aeaeae;
  --ion-color-tertiary-tint: #e2e2e2e2;
  /** success **/
  --ion-color-success: #3CB78B;
  --ion-color-success-rgb: 51, 185, 104;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #3d8361;
  --ion-color-success-tint: #8fd9ac;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #f08080;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b8655a;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #000000;

  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #dbd3d3;
  --ion-color-dark-tint: #dfd7d7;
  /** medium **/
  --ion-color-medium: #ffffff;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: rgba(226, 226, 226, 0.658);
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #f7e8e8;
  --ion-color-medium-tint: #e9e1e1;
  /** light **/
  --ion-color-light: #E8E8E8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #333;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #b0b0b0;
  --ion-color-light-tint: #e5e5e5;

  // --safe-area-top: env(safe-area-inset-top); // Top inset (e.g., status bar/navigation bar)
  // --safe-area-bottom: env(safe-area-inset-bottom); // Bottom inset (e.g., home indicator)

  --safe-area-top: 30px; // Top inset (e.g., status bar/navigation bar)
  --safe-area-bottom: 10px; // Bottom inset (e.g., home indicator)

  // Optional: Add fallback values for non-iOS devices
  --safe-area-top-fallback: 0px; // Default status bar height
  --safe-area-bottom-fallback: 0px;

  // Combined variables with fallback
  --safe-area-top-computed: var(--safe-area-top, var(--safe-area-top-fallback));
  --safe-area-bottom-computed: var(--safe-area-bottom, var(--safe-area-bottom-fallback));
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */
//   body.dark {
//     /** primary **/
//     --ion-color-primary: #3880ff;
//     --ion-color-primary-rgb: 56, 128, 255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #3171e0;
//     --ion-color-primary-tint: #4c8dff;
//     /** secondary **/
//     --ion-color-secondary: #3dc2ff;
//     --ion-color-secondary-rgb: 61, 194, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #36abe0;
//     --ion-color-secondary-tint: #50c8ff;
//     /** tertiary **/
//     --ion-modal-bg : rgba(255, 255, 255, 0.95);
//     --ion-color-tertiary: #5260ff;
//     --ion-color-tertiary-rgb: 82, 96, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #4854e0;
//     --ion-color-tertiary-tint: #6370ff;
//     /** success **/
//     --ion-color-success: #18b072;
//     --ion-color-success-rgb: 45, 211, 111;
//     --ion-color-success-contrast: #ffffff;
//     --ion-color-success-contrast-rgb: 255, 255, 255;
//     --ion-color-success-shade: #010700;
//     --ion-color-success-tint: #18b072;
//     /** warning **/
//     --ion-color-warning: #ffc409;
//     --ion-color-warning-rgb: 255, 196, 9;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0ac08;
//     --ion-color-warning-tint: #ffca22;
//     /** danger **/
//     --ion-color-danger: #eb445a;
//     --ion-color-danger-rgb: 235, 68, 90;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #cf3c4f;
//     --ion-color-danger-tint: #ed576b;
//     /** dark **/
//     --ion-color-dark: #222428;
//     --ion-color-dark-rgb: 34, 36, 40;
//     --ion-color-dark-contrast: #f7f4f4;
//     --ion-color-dark-contrast-rgb: 255, 255, 255;
//     --ion-color-dark-shade: #dbd3d3;
//     --ion-color-dark-tint: #dfd7d7;
//     --ion-border-color: #ffffff;

//     /** medium **/
//     --ion-color-medium: #ebecf1;
//     --ion-color-medium-rgb: 146, 148, 156;
//     --ion-color-medium-contrast: #ffffff;
//     --ion-color-medium-contrast-rgb: 255, 255, 255;
//     --ion-color-medium-shade: #f7e8e8;
//     --ion-color-medium-tint: #e9e1e1;
//     /** light **/
//     --ion-color-light: #121d3f;
//     --ion-color-light-rgb: 244, 245, 248;
//     --ion-color-light-contrast: #030303f8;
//     --ion-color-light-contrast-rgb: 0, 0, 0;
//     --ion-color-light-shade: #161718;
//     --ion-color-light-tint: #030303;
//     --ion-color-step-50: #000000;

//   }
//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */
//   .ios body {
//     --ion-background-color: #ffffff;
//     --ion-background-color-rgb: 18, 18, 18;
//     --ion-text-color: #222222;
//     --ion-text-color-rgb: 0, 0, 0;
//     --ion-border-color: #222222;
//     --ion-color-step-950: #1e1e1e;
//     --ion-color-step-900: #2a2a2a;
//     --ion-color-step-850: #363636;
//     --ion-color-step-800: #414141;
//     --ion-color-step-750: #4d4d4d;
//     --ion-color-step-700: #595959;
//     --ion-color-step-650: #656565;
//     --ion-color-step-600: #717171;
//     --ion-color-step-550: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-450: #949494;
//     --ion-color-step-400: #a0a0a0;
//     --ion-color-step-350: #acacac;
//     --ion-color-step-300: #b8b8b8;
//     --ion-color-step-250: #c4c4c4;
//     --ion-color-step-200: #d0d0d0;
//     --ion-color-step-150: #dbdbdb;
//     --ion-color-step-100: #e7e7e7;
//     --ion-color-step-50: #000000;
//     --ion-item-background: #ffffff;
//     --ion-toolbar-background: #ffffff;
//     --ion-tab-bar-background: #ffffff;
//     --ion-card-background: #ffffff;
//   }
//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     // --ion-toolbar-border-color: var(--ion-color-step-250);
//   }
//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */
//   .md body {
//     --ion-background-color: #ffffff;
//     --ion-background-color-rgb: 18, 18, 18;
//     --ion-text-color: #222222;
//     --ion-text-color-rgb: 0, 0, 0;
//     --ion-border-color: #222222;
//     --ion-color-step-950: #1e1e1e;
//     --ion-color-step-900: #2a2a2a;
//     --ion-color-step-850: #363636;
//     --ion-color-step-800: #414141;
//     --ion-color-step-750: #4d4d4d;
//     --ion-color-step-700: #595959;
//     --ion-color-step-650: #656565;
//     --ion-color-step-600: #717171;
//     --ion-color-step-550: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-450: #949494;
//     --ion-color-step-400: #a0a0a0;
//     --ion-color-step-350: #acacac;
//     --ion-color-step-300: #b8b8b8;
//     --ion-color-step-250: #c4c4c4;
//     --ion-color-step-200: #d0d0d0;
//     --ion-color-step-150: #dbdbdb;
//     --ion-color-step-100: #e7e7e7;
//     --ion-color-step-50: #000000;
//     --ion-item-background: #ffffff;
//     // --ion-toolbar-background: #ffffff;
//     --ion-tab-bar-background: #ffffff;
//     --ion-card-background: #ffffff;
//   }
// }