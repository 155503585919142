.info-card {
  padding: 10px !important;
  background: var(--ion-color-tertiary);
  border-radius: 6px;
  ion-label {
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 125% !important;
  }
}
.scrollable-list-modal-css .modal-wrapper {
  max-height: 50% !important;
  min-height: 50% !important;
  height: auto !important;
  width: 50% !important;
}

.support-modal-css .modal-wrapper {
  max-height: 45% !important;
  min-height: 45% !important;
  height: auto !important;
  width: 50% !important;
}

.menu-content-open {
  pointer-events: all !important;
}

ion-grid {
  //height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 20px 20px !important;
}

ion-modal:not(.custom-modal-sheet) {
  padding: 20px;
  ion-grid {
    padding: 20px 10px !important;
  }
}

app-lots-creator {
  ion-grid {
    height: 100% !important;
    background-color: var(--ion-color-medium);
  }
}

ion-content {
  --background: transparent !important;
}

ion-item {
  --background: transparent;
}

//######################## ion-label style ########################
.sc-ion-input-md-h {
  /* font-size: inherit; */
  --padding-start: 0px !important;
}
.sc-ion-label-md-h {
  white-space: normal !important;
}

// ion-label {
//   font-weight: bold;
//   white-space: normal !important;
// }

ion-label {
  font-family: "Poppins", ui-sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;

  // color: var(--ion-color-tertiary-contrast);
}

ion-label.label {
  font-weight: 700;
  font-size: 15px !important;
}
ion-label.input,
ion-item.item-border {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 27px !important;
}
ion-select-popover ion-label {
  font-weight: 500 !important;
  font-size: 15px !important;
}

ion-label.title {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: var(--ion-title-color);
}

// ion-label.title-header{
//   font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

//   font-style: normal;
//   font-weight: 700;
//   font-size: 11px;
//   line-height: 13px;
//   text-transform: uppercase;
//   color: var(--ion-title-color);
//   }
// ion-label.title-header{
//   font-family:'Poppins', sans-serif!important;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 15px;
//   line-height: 22px;

//   display: flex;
//   align-items: center;
//   text-align: center;
//   text-transform: uppercase;

//   color: var(--ion-color-success);
//   }

//Dashboard
ion-label.section-title {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: var(--ion-color-tertiary-contrast) !important;
}
ion-label.section-title.success {
  color: var(--ion-color-success) !important;
}
.popover-content {
  padding: 0 !important;
}
ion-label.label-floating {
  color: var(--ion-color-tertiary-shade);
}
//######################## ion-input style ########################

@media (max-width: 350px) {
  .nowrap {
    font-size: 13px !important;
  }
}
@media (max-width: 450px) {
  ion-input {
    input {
      font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
        "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 24px !important;
      /* identical to box height */
      display: flex !important;
      align-items: center;
      /* LightGrey - Secondary */
      color: var(--ion-color-tertiary-shade);
    }
  }
  ion-input.has-value {
    color: var(--ion-text-color);
  }
}

@media (min-width: 450px) {
  ion-input {
    input {
      font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
        "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 27px !important;
      /* identical to box height */
      display: flex !important;
      align-items: center;
      /* LightGrey - Secondary */
      color: var(--ion-color-tertiary-shade);
    }
  }
  ion-input.has-value {
    color: var(--ion-text-color);
  }
}

//######################## ion-searchbar style ########################
ion-searchbar {
  .searchbar-input.sc-ion-searchbar-md {
    -webkit-padding-start: 50px !important;
    padding-inline-start: 50px !important;
    font: 400 15px "Poppins", sans-serif !important;
  }

  .searchbar-input {
    text-align: left !important;
    background: var(--ion-color-light) !important;
    border-radius: 6px !important;
    padding: 8px 10px 8px 10px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 2px !important;
    align-items: center !important;
    justify-content: flex-start !important;
    align-self: stretch !important;
    flex-shrink: 0 !important;
    height: 40px !important;
    position: relative !important;
  }
}

//######################## ion-item style ########################

ion-item.ion-untouched,
ion-item.border-items {
  // border: 4px solid var(--ion-color-tertiary);
  background: var(--ion-color-light) !important;

  border-radius: 6px;
  display: flex;
  align-items: center;
}
.border-items {
  // border: 4px solid var(--ion-color-tertiary);
  background: var(--ion-color-light) !important;

  border-radius: 6px;
  display: flex;
  align-items: center;
}
ion-item.ion-touched {
  border-radius: 6px;
  background: var(--ion-color-light) !important;
}

ion-item.ion-touched.ion-valid.item-has-value {
  border: 2px solid var(--ion-color-success) !important;
  border-radius: 6px;
  background: var(--ion-color-light) !important;
}

ion-item.white-item {
  border-radius: 10px !important;
  border: 0px !important;
  background: var(--Blanc, #fff) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) !important;
}
ion-item.white-item.ion-touched.ion-valid.item-has-value {
  border-radius: 10px !important;
  border: 0px !important;
  background: var(--Blanc, #fff) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) !important;
}
ion-item.white-item.ion-valid.item-has-value {
  border-radius: 10px !important;
  border: 0px !important;
  background: var(--Blanc, #fff) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) !important;
}
ion-item.white-item.item-has-focus {
  border: none !important;
}

ion-item.no-border {
  border: 4px solid transparent !important;
  border-radius: 6px !important;
  background: var(--ion-color-light) !important;
}
ion-item.item-select.item-has-value {
  border: 2px solid var(--ion-color-success) !important;
  border-radius: 6px;
  background: var(--ion-color-light) !important;
}
ion-item.ion-touched.ion-invalid {
  border: 2px solid var(--ion-color-danger) !important;
  border-radius: 6px;
  background: var(--ion-color-light) !important;
}

ion-item.item-checkbox-checked {
  border: 2px solid var(--ion-color-success);
  border-radius: 6px;
}

ion-item.item-has-focus {
  background: white !important;
  border: 2px solid var(--ion-color-success) !important;

  ion-label.label-floating {
    color: var(--ion-color-tertiary-shade) !important;
  }
}
ion-item {
  --inner-padding-end: 6px !important;

  ion-label {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", Arial !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
  input {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", Arial !important;

    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
}
.item-border-tertiary {
  border: 2px solid var(--ion-color-tertiary) !important;
  border-radius: 6px !important;
}

.item-border-danger {
  border: 2px solid var(--ion-color-danger) !important;
  border-radius: 6px !important;
}

.item-border-success {
  border: 2px solid var(--ion-color-success) !important;
  border-radius: 6px !important;
}

//######################## ion-select style ########################

.in-item {
  position: static;
  max-width: none;
}

ion-select {
  font-size: 14px;
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
  font-style: normal;
  font-weight: 500;
  color: var(--ion-color-tertiary-contrast) !important;
}

//######################## ion-popover style ########################

ion-popover {
  width: 100% !important;
}

.popover-content {
  position: relative !important;
  margin: 0 0 !important;
  left: 0 !important;
  top: 0 !important;
  ion-select-popover {
    max-height: 60vh !important;
  }
}
.popover-content:not(app-modal-cashflow-entry-list) {
  border-radius: 12px !important;
}
.small-popover ion-list {
  max-height: 50vh !important;
  overflow-y: auto;
}
ion-list {
  background: transparent !important;
}

.popover_class .popover-content {
  ion-grid {
    // height: 80vh;
    overflow: hidden;
    margin-top: 3%;
  }
  border-radius: 0px !important;
  background-color: transparent !important;
  --backdrop-opacity: transparent !important;
  background: transparent !important;
  --box-shadow: none;
  justify-content: center;
  width: 100vw;
  overflow: hidden;
}

.popover_class.diario {
  app-modal-cashflow-entry-list {
    justify-content: center;
    align-items: center;
  }
}

ion-backdrop {
  --backdrop-opacity: 0.7;
}
ion-backdrop {
  opacity: 0.5 !important;
  background: black !important;
}

.pop-over-style-list-checkbox {
  .popover-content {
    --width: 60vw !important;
    --height: 80vh !important;
  }
}
.pop-over-style-login {
  .popover-content {
    --width: 60vw !important;
    --height: 50vh !important;
    background-color: var(--ion-color-tertiary-tint) !important;

    ion-label {
      font-family: "Poppins" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 19px !important;
    }
  }
}
.pop-over-style-info {
  --width: 75vw !important;

  .popover-content {
    --height: auto !important;
    background-color: var(--ion-color-tertiary-tint) !important;

    ion-label {
      font-family: "Poppins" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 19px !important;
    }
  }
}
.pop-over-style-login-prefix {
  .popover-content {
    --width: 40vw !important;
    --height: 50vh !important;
    ion-label {
      font-family: "Poppins" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 19px !important;
    }
  }
}
.pop-over-style {
  .popover-content {
    --width: auto !important;
  }
}
.pop-over-style-check {
  .popover-content {
    width: 90vw !important;
  }
}

.pop-over-style-confirm {
  .popover-content {
    --width: 80vw !important;
    border-radius: 30px !important;
  }
}
.pop-over-style-confirm-logout {
  .popover-content {
    --width: 80vw !important;
    border-radius: 30px !important;
  }
}

.pop-over-style-video-prefix {
  .popover-content {
    --width: 85vw !important;
    // --height: 47vh !important;
    background-color: transparent;
  }
}

.alert-checkbox-label.sc-ion-alert-md {
  white-space: pre-wrap !important;
}

.pop-over-style-languages-selection {
  .popover-content {
    --width: 80vw !important;
    --border-radius: 30px !important;
  }
}
.popover-custom {
  --box-shadow: 0px;
  //  bottom: 60px;
  ion-backdrop {
    opacity: 0.6 !important;
  }
  height: auto;
  left: auto !important;
  right: auto !important;
  .popover-content {
    width: 90%;
  }

  .popover-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    --background: transparent;
    --border-radius: 30px;

    popover-content {
      overflow: auto;
      height: 100vh;
    }
  }
}

.percentagePicker{
  .popover-wrapper {
    width: 50%!important;

  }
}

app-lang-popover,
app-popover,
app-confirm-modal {
  ion-toolbar {
    padding: 10px 20px !important;
    // margin: 0!important;
    // width: 100%!important;
    --background: #efefefd9 !important;
  }
  ion-label {
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 19px !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: #53605a;
    padding: 10px;
  }
  ion-button {
    ion-label {
      font-weight: 500 !important;
      color: var(--ion-color-medium) !important;
    }
  }
}
app-confirm-modal > ion-button {
  --border-radius: 0px !important;
}
//######################## ion-segment-button style ########################
app-personal-data ion-segment,
ion-segment {
  border-radius: 8px 8px 8px 8px;
  background-color: transparent !important;
  margin-top: 3%;
}

.ion-segment-vertical {
  border-radius: 8px 8px 8px 8px;
  background-color: transparent !important;
  margin-top: 3%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ion-segment-button-vertical {
  --border-width: 0.7;
  border-style: double;
  --border-radius: 7px 7px 7px 7px;
  border: 5px solid var(--ion-color-tertiary-shade);
  font-size: 15px;
  color: var(--ion-color-tertiary-contrast);
  background-color: var(--ion-color-tertiary);
  ion-label {
    font-size: 15px;
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    font-style: normal;
    font-weight: 700;
  }
  --background-checked: var(--ion-color-success);
  max-width: -webkit-fill-available;
  // width: 80%;
  margin: auto;
  margin-top: 0.2rem;
  margin-bottom: 0.3rem;
}

ion-segment {
  border-radius: 8px 8px 8px 8px;
  background-color: transparent !important;
  margin-top: 3%;
  // max-height: 50px;
}

ion-segment-button {
  --border-width: 0.7;
  border-style: double;
  --border-radius: 30px !important;
  border: 5px solid var(--ion-color-tertiary-shade);
  font-size: 15px;
  color: var(--ion-color-tertiary-contrast);
  background-color: var(--ion-color-tertiary);
  ion-label {
    font-size: 15px;
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    font-style: normal;
    font-weight: 700;
  }
  --background-checked: var(--ion-color-success);
  width: 100%;
}
.segment-button-checked {
  border-color: var(--ion-color-success-tint);
  ion-label {
    font-size: 15px;
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    font-style: normal;
    font-weight: 700;
    color: var(--ion-color-success-contrast) !important;
  }
  ion-button {
    box-shadow: 5px 5px 5px var(--ion-color-medium);
    text-transform: none !important;
    --background: var(--ion-color-success);
    --background-color: var(--ion-color-success);
    --background-checked: var(--ion-color-success);
  }
  background: var(--ion-color-success);
}

ion-segment-button.segment-button-checked {
  border-color: var(--ion-color-tertiary-contrast);
  // box-shadow: 5px 5px 5px var(--ion-color-medium);
  --background: var(--ion-color-tertiary-contrast);
  --background-color: var(--ion-color-tertiary-contrast);
  --background-checked: var(--ion-color-tertiary-contrast);
  background: var(--ion-color-tertiary-contrast);
  --indicator-color: transparent !important;
  --indicator-color-checked: transparent !important;
  --border-width: 0px;
  --indicator-color-checked: 0;
  ion-label {
    font-size: 15px;
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    font-style: normal;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
  }
  ion-icon {
    color: #ffffff;
  }
}

ion-segment-button.in-segment-color {
  --ion-color-base: transparent !important;
}

//######################## ion-button style ########################

ion-button {
  --border-radius: 10px !important;
  text-transform: none !important;
  --ripple-color: transparent !important;

  font-style: normal;
  font-weight: 500 !important;
  text-overflow: ellipsis;
  white-space: pre-wrap !important;
  text-align: center !important;
  display: flex;
  height: 40px;
  gap: 8px;
  ion-label {
    // text-transform: capitalize!important;
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
  }
}

ion-button.ion-color-success {
  // box-shadow: 0px 4px 0px var(--ion-color-success-shade) !important;
  --box-shadow: 0px 0px 6px 0px rgba(35, 72, 59, 0.15) !important;
}
ion-button.ion-color-danger {
  --box-shadow: 0px 0px 6px 0px rgba(35, 72, 59, 0.15) !important;
}
ion-button.ion-color-tertiary {
  --box-shadow: 0px 0px 6px 0px rgba(35, 72, 59, 0.15) !important;
}
ion-button.ion-color-light {
  --box-shadow: 0px 0px 6px 0px rgba(35, 72, 59, 0.15) !important;
}
.button-not-clicked {
  --border-width: 0.5px !important;
  --background: white;
  --color: var(--ion-color-dark) !important;
  --border-color: var(--ion-color-dark);
  color: black !important;
}

.button-clicked {
  --background: var(--ion-color-success);
  --color: white !important;
  color: white !important;
  --border-color: transparent !important;
}

.confirm-btn {
  color: var(--ion-color-success) !important;
}

.cancel-btn {
  color: var(--ion-color-danger) !important;
}

.modal-cashflow-entry {
  height: 100%;
}

// ion-toast.custom-toast::part(button) {
//   border-radius: 6px;
//   font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
//     "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
//   font-style: normal;
//   font-weight: 500;
//   // box-shadow: 0px 4px 0px var(--ion-color-tertiary-shade) !important;
//   background-color: var(--ion-color-tertiary) !important;
//   color: var(--ion-color-tertiary-contrast) !important;
//   ion-label {
//     text-transform: uppercase;
//   }
// }

//######################## ion-checkbox and radio style ########################

ion-checkbox {
  --checkmark-width: 3px;
  --border-color: var(--ion-color-tertiary-shade);
  --border-width: 3px;
}
.no-scroll .scroll-content {
  overflow: hidden !important;
}
ion-checkbox {
  --checkmark-width: 3px;
  --border-color: var(--ion-color-tertiary-shade);
  --border-width: 1.5px;
}
ion-checkbox.checkbox-icon {
  background-color: red;
}
//########################

ion-radio {
  --color-checked: var(--ion-color-success);
  background-color: white;
  border-radius: 50%;
  // border-style: double;
}
.radio-icon {
  border-width: 10px;
}
ion-radio.radio-icon {
  border-width: 10px;
}

ion-radio {
  color: var(--ion-color-success);
}
ion-radio {
  --color-checked: var(--ion-color-success);
  --color: var(--ion-color-tertiary-shade);
  --border-width: 3px;
  --inner-border-radius: 50%;
}

//######################## card style ########################

.card-checked {
  border-radius: 5px;
  box-shadow: 0px 4px 0px var(--ion-color-success-shade);
}

.card {
  border-radius: 5px;
  box-shadow: 0px 4px 0px var(--ion-color-tertiary-shade);
}
.card_info {
  padding: 20px;

  background: #dbdbdb;
  border-radius: 6px;
}

app-date-filter-modal {
  ion-chip {
    padding-inline: 0px !important;
  }
}

//######################## ion-dateime style ########################
ion-datetime {
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center;
  color: var(--ion-color-tertiary-contrast) !important;
  padding-left: 0px;
}
ion-icon[src="/assets/icon/app-icon/icn-calander.svg"] {
  margin-inline-start: 0px !important;
}
// @media (max-width: 370px) {
//   ion-datetime.resize-date {
//     font-size: 8px !important;
//   }
// }

@media (max-width: 400px) {
  ion-datetime.resize-date {
    font-size: 3.5vw !important;
  }
}
@media (max-width: 370px) {
  ion-datetime.resize-date {
    font-size: 3vw !important;
  }
}
// @media (max-width: 370px) {
//   ion-datetime.resize-date {
//     font-size: 10px !important;
//   }
// }
// @media (max-width: 400px) {
//   ion-datetime.resize-date {
//     font-size: 13px !important;
//   }
// }
//######################## List style ########################

.categories-list {
  display: flex;
  flex-flow: row wrap;
  flex: 4;
  justify-items: center;
  // align-content: space-between;
  align-content: center;
  flex-direction: row;

  text-align: center;
  // margin-right: 2rem;
  // margin-left: 1rem;
}

.categories-list-item {
  display: flex;
  flex-direction: column;
  // flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  max-width: 95px;
  min-height: 100px;
  max-height: 100px;
  min-width: 120px;

  cursor: pointer;
}
.categories-list-item-animal {
  display: flex;
  flex-direction: column;
  // flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  min-height: 150px;
  max-height: 150px;
  min-width: 150px;

  cursor: pointer;
}
.categories-list-item-content {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

//######################## custom style ########################

.productdiv {
  overflow: hidden;
  background-color: white;
  border-radius: 15px;
  width: 100px;
  height: 80px;
}
.red-start {
  color: var(--ion-color-danger);
}

.nowrap {
  word-wrap: break-word;
  white-space: nowrap !important;
}

.activity-title {
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", Arial !important;
  color: var(--Noir, #333) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important;
  // padding:20px 20px 0px !important;
  display: block;
  text-align: left !important;
}

ion-header {
  .activity-title {
    padding: 0px 20px 0px !important;
  }
}

.activity-main-title {
  font-weight: bold;
  font-size: 50px;
  margin: 50px auto 0px;
  display: block;
  text-transform: uppercase;
}

.list-items-class {
  border: 4px solid var(--ion-color-tertiary-shade);
  border-radius: 6px;
  background-color: var(--ion-color-tertiary);

  --background: "black";
  width: 90%;
  margin: 10px auto 0px;
  // padding: 1%;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}
.info-icon {
  position: absolute;
  right: 10%;
  bottom: 15%;
  size: 20px;
  color: #18b072;
  right: 10px;
}

.table-item {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: var(--ion-color-tertiary-contrast);
  display: flex;
  align-items: center;
}
.table-header {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: var(--ion-color-tertiary-contrast);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.table-total {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: var(--ion-color-tertiary-contrast);
  display: flex;
  align-items: center;
}
.mat-horizontal-content-container {
  padding: 0px !important;
}
.list-items-class-prog {
  border: 4px solid var(--ion-color-tertiary-shade);
  background-color: var(--ion-color-medium-contrast);
  border-radius: 8px 8px 8px 8px;
  --background: "black";
  width: 90%;
  margin: 10px auto 0px;
  padding: 1%;
}
.sub-product-container {
  position: absolute;
  right: 7px;
  top: 7px;
}

//######################## table style ########################

.table-card {
  // border: 1px solid var(--ion-color-tertiary);
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  padding-left: 20px;
  padding-right: 20px;
  .table-header {
    // width: 100%;
    padding: 10px 0px;
    background-color: #fff;
    border-bottom: 1px solid var(--ion-color-tertiary);
    ion-label {
      font-family: "Poppins", sans-serif !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 110% !important;
      color: var(--Gris-Dark, #828282);
    }
  }
  .last-item-table {
    border-top: 1px solid var(--ion-color-tertiary);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // ion-row:not(.last,.last-item-table, .no-border) {
  //   border-bottom: 1px solid var(--ion-color-tertiary);
  // }
  .item-title,
  .item-data {
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 110% !important;
    color: var(--ion-color-tertiary-contrast);
  }

  // .item-data{
  //   // text-align: center;
  //   // display: flex;
  //   // justify-content: center;
  //   // align-items: center;
  // }
  // ion-label:not(.item-title, .table-header ion-label) {
  //   font-family:"Poppins", sans-serif !important;
  //   font-style: normal !important;
  //   font-weight: 500 !important;
  //   font-size: 14px !important;
  //   line-height: 110% !important;
  // }
}

.card-size {
  min-height: 20vh;
  min-width: 25vw !important;
}
.sub-product-container {
  position: absolute;
  right: 7px;
  top: 7px;
}

app-team-interface-lite.ion-page {
  justify-content: normal !important;
}

.border-item {
  background-color: var(--ion-color-light);
  border: 4px solid var(--ion-color-tertiary);
  border-radius: 6px;
  ion-label {
    font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: var(--ion-color-tertiary-contrast);
  }
}

.delimitation-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  .delimitation-header {
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: var(--ion-color-tertiary-contrast);
    text-align: left;
  }
  .delimitation-option-box {
    margin-top: 20px;
    text-align: start;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .delimitation-option {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background-color: var(--ion-color-primary);
      box-shadow: 0px 3px 7px var(--ion-color-tertiary-shade);
      width: fit-content !important;
      padding: 15px;

      p {
        border-radius: 6px;
        cursor: pointer;
        margin: 0px;
        font-family: "Poppins", sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
        color: var(--ion-color-medium);
      }
    }
    ion-icon {
      font-size: 20px;
      margin-left: 7px;
    }
    .later {
      background-color: var(--ion-color-tertiary) !important;
      margin-bottom: 20px;

      p,
      ion-icon {
        color: #333 !important;
      }
    }
  }
}

app-lots-creator,
app-lots-overview {
  app-map {
    padding: 10px 15px 0px !important;
  }
  .agm-map-container-inner {
    border-radius: 10px !important;
  }
}

ion-slides {
  // padding-top: 30px;
  overflow-y: auto !important;
  .swiper-pagination {
    position: fixed !important;
    bottom: 0px !important;
    background: white !important;
    // margin-bottom: 10px;
  }
}

.filter-header {
  border-style: solid;
  border-color: var(--gris-light, #e8e8e8);
  border-width: 1px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
  margin-top: 10px;
}

#camera-icon {
  font-size: 19px;
  position: relative;
  bottom: 26px;
  right: -76%;
  color: white;
  background: var(--ion-color-primary);
  border-radius: 50%;
  padding: 4px;
}

.entry-line,
.entry-line * {
  box-sizing: border-box;
}
.entry-line {
  border-style: solid;
  border-color: var(--gris-light, #e8e8e8);
  border-width: 0px 0px 1px 0px;
  padding: 4px 0px 4px 0px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}
.main {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.category {
  padding: 8px 0px 8px 5px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.category-label {
  color: var(--noir, #333333);
  text-align: left;
  font-family: "Poppins", ui-sans-serif, system-ui, "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial" !important;

  font-size: var(--paragraphe-small-medium-font-size, 14px);
  font-weight: var(--paragraphe-small-medium-font-weight, 500);
  position: relative;
}
.arrow {
  padding: 0px 0px 0px 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.right-arrow {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}

.DragableBar {
  padding-top: 8px;
  padding-bottom: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}
.line {
  width: 40px;
  height: 3px;
  background: var(--ion-color-light);
  border-radius: 999px;
}


#sync-animation-icon{
  animation: rotateAnimation 3s infinite;
}

@keyframes rotateAnimation {
  50% {transform: rotate(360deg);}
}