.block-app {
    width: 80%;
    margin: 0px auto !important;
}

.big-button {
    position: fixed;
    bottom: 80px;
    margin: 0px auto;
    width: 200px;
    left: 50%;
    margin-left: -100px;
}

.swiper-wrapper h2,
.swiper-wrapper p,
h1 {
    text-align: center;
}

.swiper-wrapper p {
    padding: 0px 35px;
}

h1 {
    margin-top: 120px;
    font-size: 20px;
    color: black;
    text-transform: uppercase;
}

.swiper-wrapper h1 {
    padding: 0px 30px;
}

.swiper-wrapper h3 {
    text-align: center;
    padding: 10px 33px;
    font-weight: normal;
    font-size: 15px;
}

.swiper-wrapper h3 {
    text-align: center;
}

.swiper-wrapper .card-content {
    margin-bottom: 0px;
}

.swiper-wrapper .card-content.card-data-table {
    margin-bottom: 0px !important;
}

.author-custom {
    position: fixed;
    top: 23px;
    border: solid 2px white;
    right: 29px !important;
}

.type-wrap {
    width: 70%;
    background: #EEE;
    border: solid 1px #9cbb9c !important;
}

.type-wrap-container {}

.type-wrap-container-active {
    display: none;
}

.type-avatar {
    width: 60px;
    float: right;
    margin-top: -19px;
    margin-right: -40px;
}

.type-avatar-min {
    width: 140px;
    margin: 0px auto;
    position: absolute;
    left: 50%;
    z-index: 19;
    margin-left: -70px;
    margin-top: -14px;
}

.type-avatar-big {
    height: 60%;
    margin: 0px auto;
    padding: 20px;
}

.progressbar {
    height: 19px;
}

.progressbarbig {
    height: 24px;
}

.img-app {
    width: 100%;
    margin: 0px auto;
}

#demo-inline-progressbar-big {
    margin-top: 0px;
}

.f7-icons-big {
    font-size: 50px;
    margin: 0px auto;
    color: #a91907;
}

#view-today h2 {
    margin-top: 0px;
    color: #a91907;
}

#view-today .swiper-wrapper p {
    padding-top: 0px;
}

#view-today .swiper-slide {
    margin-top: 50px;
}

#view-today #view-starting-save .swiper-slide {
    margin-top: 0px;
}

#view-starting-save.swiper-slide-program .swiper-slide {
    margin-top: 0px;
}

.type-wrap {
    position: relative;
    background: #bfd9d3;
    border-radius: .4em;
    color: black;
    font-weight: bold;
    min-height: 19px;
    padding: 5px 10px;
    font-size: 12px;
    width: 80%;
    border: 0px;
    z-index: 20;
    -webkit-box-shadow: -88px 19px 0px -122px rgba(238, 14, NaN, 0.96);
    -moz-box-shadow: -88px 19px 0px -122px rgba(238, 14, NaN, 0.96);
    box-shadow: -88px 19px 0px -122px rgba(238, 14, NaN, 0.96);
}

.type-wrap a {
    background: #18b072;
}

.type-wrap.type-wrap-right:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 24px solid transparent;
    border-left-color: #bfd9d3;
    border-right: 0;
    border-top: 0;
    margin-top: -12px;
    margin-right: -24px;
}

.type-wrap.type-wrap-min:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-top-color: #277765;
    border-bottom: 0;
    border-right: 0;
    margin-left: -6px;
    margin-bottom: -12px;
}

.type-wrap-farm-active .type-wrap-min:after {
    left: 67px;
}

.type-wrap.type-wrap-bottom:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-top-color: #bfd9d3;
    border-bottom: 0;
    border-left: 0;
    margin-left: -6px;
    margin-bottom: -12px;
}

.type-wrap-close {
    margin: 10px 0px;
}

.type-wrap a:hover,
.type-wrap a:active {
    color: white !important;
    background: #30957d !important;
}

.avatar-name {
    text-align: center;
}

.demo-swiper.demo-swiper-auto .swiper-slide {
    width: 85%;
}

.demo-swiper.demo-swiper-auto .swiper-slide:nth-child(2n) {
    width: 85%;
}

.demo-swiper.demo-swiper-auto .swiper-slide:nth-child(3n) {
    width: 85%;
}

.swiper-wrapper p.row-no-padding {
    padding: 0px;
}

.swiper-wrapper .block-centered,
.block-centered {
    padding: 20px 50px 0px;
    margin: 0px;
}

.swiper-wrapper .block-centered .item-content {
    padding: 0px;
}

.swiper-pagination-progressbar {
    height: 24px !important;
    position: fixed;
    bottom: 64px;
    top: auto !important;
    box-shadow: -1em 0 .4em #EEE;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #18b072;
}

.popup p {
    width: 80%;
    color: black;
    font-size: 12px;
    text-align: justify;
}

.view-landing {
    /*margin-bottom: 500px;	*/
}

.view-sliders .view,
#view-starting-avatar {
    overflow-y: auto;
}

ul.steps li {
    float: left;
    color: #726b6b;
    background: #EEE;
    list-style-type: none;
    text-align: center;
    font-size: 11px;
    line-height: 33px;
    text-transform: uppercase;
    width: -webkit-calc(20% - 1px);
    width: -moz-calc(20% - 1px);
    width: calc(20% - 1px);
    border-right: solid 1px #DDD;
}

ul.steps li.summary_menu_current,
ul.steps li.summary_menu_done {
    background: #18b072;
    color: white;
}

ul.steps li.summary_menu_white {
    background: white;
    color: black;
}

ul.steps li.summary_menu_red {
    background: red;
    color: white;
}

ul.steps li.summary_menu_grey {
    background: grey;
    color: #999;
}

.summary_menu_current.summary_menu_current_active,
.summary_menu_current_active {
    background: white !important;
    font-weight: bold !important;
    color: black !important;
}

ul.steps.assets_debts li {
    width: calc(33.33% - 1px);
}

ul.steps.net_income li {
    width: calc(20% - 1px);
}

ul.steps.net_income_harvest {
    margin-top: 47px;
    background: white;
    width: 85%;
    margin-left: 32px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
}

ul.steps.net_income_harvest li {
    width: calc(50% - 1px);
    line-height: 22px;
    font-size: 9px;
}

ul.steps {
    width: 100%;
    z-index: 2000;
    position: fixed;
    top: 0px;
    padding: 0px;
    margin: 0px;
}

.swiper-pagination-progressbar {
    background: #CCC;
}

.block-menu-fixed {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 82px;
    border-top: solid 1px #EEE;
    background: #f4f6fa;
    margin-top: 10px !important;
    padding-top: 20px !important;
    z-index: 5000;
}

.block-menu-fixed.insideTab {
    width: 95%;
    bottom: 70px;
}

.block-menu-popup-fixed {
    position: fixed;
    bottom: 0px;
    z-index: 5000;
    width: 100%;
    border-top: solid 1px #DDD;
    margin-top: 10px !important;
    padding-top: 10px !important;
    height: 48px;
    background: #EEE;
}

.block-menu-fixed a,
.block-menu-fixed button,
.block-menu-popup-fixed button .block-menu-popup-fixed a {
    text-transform: uppercase;
    font-size: 15px;
}

.block-menu-fixed .f7-icons {
    float: right;
}

.block-menu-fixed .f7-icons.f7-icons-back {
    float: left;
    float: auto;
    padding-right: 10px;
}

.media-list .item-title,
li.media-item .item-title {
    font-weight: normal;
}

.block-no-padding {
    padding: 0px !important;
}

.block-no-padding .inset {
    margin: 0px;
}

.button-left {
    float: left;
}

.colored-value {
    color: rgb(223, 25, 25);
    font-weight: bold;
    text-decoration-style: wavy;
}

.colored-value.contoured {
    background: #fae488;
    color: black;
    padding: 1px 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.colored-value-valid,
.colored-value-valid i {
    color: #18b072;
}

.app-subtitle {
    color: black !important;
    font-size: 12px !important;
    margin-bottom: 10px;
    font-weight: bold !important;
}

.item-input-last {
    display: none !important;
}

.list .item-label {
    color: black !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.list .item-content {
    margin-top: 10px;
    width: 100%;
}

.list .item-content.item-checkbox {
    margin-top: 0px;
}

.list.inset {
    margin-bottom: 0px;
}

.button-cancel,
.remove-button {
    background: #a91907 !important;
}

.button-confirm {
    background: #18b072;
}

.close-button {
    position: fixed;
}

.list {
    width: 85%;
    margin: 0px auto;
    margin-top: 20px;
}

#app .card {
    margin: 0px auto !important;
    width: 90%;
    height: auto;
    margin-top: 20px;
}

.data-table tbody td,
.data-table tbody th {
    height: 32px;
}

.segmented {
    width: 90%;
    margin: 0px auto;
    margin: 18px auto;
}

.navbar-popup {
    background: #18b072;
    position: fixed !important;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    color: white;
}

.masstext {
    padding: 17px;
    margin-top: 50px;
    text-align: justify;
    font-size: 14px;
    color: black;
}

.popup-wrap {
    padding: 20px;
}

.close-button {
    z-index: 10 !important;
    display: flex !important;
    width: 28px !important;
    color: #ff2d55;
    height: 28px !important;
    align-items: center !important;
    justify-content: center !important;
    background: white !important;
    border-radius: 20px !important;
    right: 10px !important;
    top: 8px !important;
}

.block-popup {
    margin-top: 30px;
}

.block-popup .list {
    width: 100%;
    margin-top: 30px;
}

.list ul:after,
.list ul:before {
    display: none;
}

.swiper-slide .card-infos-programs {
    padding: 0px;
}

.ucol-15 {
    width: 15%;
}

.ucol-20 {
    width: 20%;
}

.ucol-25 {
    width: 25%;
}

.ucol-45 {
    width: 45%;
}

.ucol-55 {
    width: 55%;
}

.ucol-49-padding-10 {
    width: calc( 49% - 3px);
}

.ucol-35 {
    width: 35%;
}

.ucol-65 {
    width: 65%;
}

.ucol-75 {
    width: 75%;
}

.ucol-70 {
    width: 70%;
}

.ucol-100 {
    width: 100%;
}

.ucol-50 {
    width: 50%;
}

.ucol-80 {
    width: 80%;
}

.ucol-40 {
    width: 40%;
}

.ucol-60 {
    width: 60%;
}

.ucol-30 {
    width: 30%;
}

.ucol-33 {
    width: 33%;
}

i.fa,
i.fas,
i.fal,
i.fad,
i.far {
    color: #b1532b;
}

.color-theme-pink {
    --f7-theme-color: #a91907;
    --f7-theme-color-rgb: #a91907;
    --f7-theme-color-shade: #a91907;
    --f7-theme-color-tint: #a91907;
}

.open-comment i {
    color: white;
}

.media-list {
    width: 100%;
    padding-bottom: 150px;
}

.media-list ul li {
    border-bottom: solid 1px #EEE;
}

.item-checkbox .fa-plus {
    margin-right: 20px;
    margin-left: 3px;
}

.tips {
    background: #ffe6c0;
    color: black;
    padding: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 12px;
}

.swiper-slide .popular-authors li a,
.route-page .popular-authors li a,
.tab-page .popular-authors li a {
    margin-bottom: 5px;
    padding-top: 5px;
}

.swiper-slide .popular-authors li,
.route-page .popular-authors li,
.tab-page .popular-authors li {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: solid 1px #d3d3d3;
    border-bottom: solid 3px #d3d3d3;
    padding: 0px;
    margin: 5px 10px;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(236, 236, 236, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(236, 236, 236, 1) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(236, 236, 236, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ececec", GradientType=1);
}

.route-page .popular-authors li {
    margin: 5px 0px;
}

.tab-page .popular-authors li {
    padding: 0px 15px;
}

.tab-page .popular-authors .author-description {
    font-size: 12px;
}

.route-page .popular-authors li,
.tab-page .popular-authors li {
    margin: 5px 0px;
}

.list ul ul {}

.route-page .list ul {
    background: none;
}

.swiper-slide .popular-authors .author-name {
    font-size: 16px;
}

.swiper-slide .popular-authors .author-description {
    font-size: 14px;
}

.setOptionDiv .popular-authors {
    padding-left: 0px;
}

.setOptionDiv .list {
    width: 100%;
}

.setOptionDivSub {
    margin-top: 10px;
}

.card-content-padding {
    margin-bottom: 0px !important;
    padding: 10px;
}

.card-prices .card-header {
    background: rgb(190, 52, 35);
    background: -moz-linear-gradient(60deg, rgba(190, 52, 35, 1) 0%, rgba(169, 25, 7, 1) 100%, rgba(73, 124, 228, 1) 100%);
    background: -webkit-linear-gradient(60deg, rgba(190, 52, 35, 1) 0%, rgba(169, 25, 7, 1) 100%, rgba(73, 124, 228, 1) 100%);
    background: linear-gradient(60deg, rgba(190, 52, 35, 1) 0%, rgba(169, 25, 7, 1) 100%, rgba(73, 124, 228, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#be3423", endColorstr="#497ce4", GradientType=1);
    color: white;
    font-size: 14px;
    min-height: 0px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
}

.card-prices table {
    width: 100%;
    font-size: 13px;
}

.card-prices {
    color: black;
}

.card-prices table tr td {
    border-bottom: solid 1px #EEE;
}

.card-prices table tr.total {
    font-weight: bold;
}

.card-prices table tr td.card-right {
    text-align: right;
}

.card-prices table tr td.card-center {
    text-align: center;
}

.card-prices button {
    width: 40px;
}

.card-prices .card-right button {
    float: right;
}

.type-wrap-page {
    margin-top: 10px;
}

.popover-inner>p {
    margin: 0px;
    line-height: 19px;
    font-size: 14px;
    color: black;
    font-weight: bold;
    text-align: left;
    padding: 10px 15px 15px 20px;
}

.popover img {
    width: calc(100% - 8px);
    border-radius: 13px;
    border: solid 4px white;
}

.toolbar-inner:not(.toolbar-inner-main) {
    background: #18b072;
    color: white;
}

.toolbar-inner .sheet-close {
    color: white;
    padding-right: 21px;
}

.ios .toolbar:not(.toolbar-main) {
    height: 45px;
}

video {
    margin-top: 0px;
}

.block-video {
    width: 90%;
    margin: 0px auto !important;
}

.block-video img {
    width: 100%;
}

.explainers {
    margin-top: 20px;
    border: solid 1px #AAA;
    width: 86%;
    margin: 0px auto;
    margin-top: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: white;
}

.explainers-no-margin {
    margin-top: 0px;
    margin-bottom: 0px;
}

.explainer {
    width: 90%;
    margin: 0px auto;
    text-align: center;
    border-bottom: solid 1px #EEE;
    padding: 5px 0px;
}

.explainer_double {
    width: 40%;
    margin: 0px auto;
    text-align: center;
    border-bottom: solid 1px #EEE;
    padding: 5px 0px;
}

.explainer:last-child {
    border-bottom: 0px;
}

.explainer .explainer-big {
    font-size: 30px;
    color: #a91907;
}

.explainer .explainer-medium {
    font-size: 20px;
    color: #a91907;
}

.sub-explainers .explainer .explainer-big {
    font-size: 20px;
}

.explainer .explainer-description {
    color: #675858;
    font-size: 14px;
    font-weight: bold;
    margin: 0px 10px;
}

.explainer .explainer-description-plus {
    color: #675858;
    font-size: 14px;
    font-weight: bold;
    border-bottom: solid 1px #EEE;
    padding: 10px;
}

.explainer .explainer-description-plus:last-child {
    border-bottom: 0px;
}

.sub_explainers .explainer-big {
    font-size: 20px;
}

.sub_explainers .explainer-description {
    font-weight: normal;
}

.explainers .sub_explainers .explainer {
    width: 100%;
    border-bottom: 0px;
}

.explainers .sub_explainers.sub_explainers_one .explainer {
    width: 100%;
}

.explainers .sub_explainers .explainer:first-child {
    float: left;
}

.explainers .sub_explainers .explainer:last-child {
    float: right;
}

.explainers .sub_explainers.sub_explainers_two_columns .explainer:first-child {
    float: left;
}

.explainers .sub_explainers.sub_explainers_two_columns .explainer:last-child {
    float: right;
}

.explainers .explainer_mini_bottom {
    width: 100%;
    clear: both;
    font-size: 10px;
    color: black;
}

.explainers .sub_explainers_two_columns .explainer {
    width: 47%;
}

.clearfix {
    clear: both;
}

.explainers .explainer-title {
    width: 100%;
    background: #EEE;
    font-weight: bold;
    text-align: center;
    padding: 10px 0px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.explainers .explainer-title-inverted {
    width: 100%;
    background: #EEE;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    padding: 10px 0px;
    -webkit-border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    -moz-border-radius-bottomright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.explainers button {
    margin-top: 10px;
    width: 80%;
    margin: 0px auto;
}

.explainers.explainers-no-borders {
    border: 0px;
}

.explainers a {
    margin-top: 10px;
}

.explainers .explainer-slogan {
    width: 100%;
    background: green;
    font-weight: bold;
    color: white;
    font-size: 13px;
    text-align: center;
    padding: 10px 0px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.explainer-description img {
    width: 50%;
    margin: 0px auto;
}

.far.fa-check-circle {
    color: green;
}

.explainer table {
    width: 100%;
}

.explainer table tr td {
    border: solid 1px #EEE;
    text-align: left;
    text-transform: uppercase;
    font-size: 13px;
    padding: 5px 10px;
}

.explainer-table-mini table {
    width: 100%;
}

.explainer-table-mini table tr td {
    border: 0px;
    border-bottom: solid 1px #EEE;
    text-align: left;
    padding: 4px;
    font-size: 11px;
}

.explainer-table-label {
    width: 50px;
}

.messagebar textarea {
    background: white !important;
}

.list-contact {
    width: 100%;
}

.list-contact li {
    color: black;
    border-bottom: solid 1px #CCC;
}

.list-contact .item-content {
    margin-top: 0px;
}

.list-contact .item-link .item-inner {
    padding-left: 0px;
}

.page-content-dashboard i {
    color: white;
}

.page-content-simplelist {
    padding-top: 0px;
}

#app .block-for-simplelisting .card {
    width: 95%;
}

.icons_menus_form .card-infos-programs {
    padding: 0px 20px;
}

.page-content-dashboard .icons_menus a {
    width: calc(50% - 21px);
}

.icons_menu_icon {
    margin-right: 7px;
}

.icons_menu_icon i {
    width: 23px;
    text-align: center;
}

.page-content .icons_menu_icon i {
    color: #22b87b;
}

.dialog .dialog-inner .dialog-title {
    display: none;
}

.media-list ul li ul li .item-title {
    font-size: 14px;
}

h1.active-slide {
    font-size: 70px;
    margin-top: 70px;
}

.tab-active .page-current {
    /*	height: 200%;*/
    /* height: 100%; */
}

.popup_bloc {
    border: solid 1px #EEE;
    padding: 10px;
    width: calc( 100% - 20px);
    -webkit-border-radius: 1px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius: 1px;
    -moz-border-radius-bottomleft: 10px;
    border-radius: 1px;
    border-bottom-left-radius: 10px;
    margin-bottom: 5px;
}

.popup_bloc_title {
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #a91907;
    color: white;
    text-transform: uppercase;
    padding: 5px;
    font-size: 10px;
}

.item-input-options-large {
    width: 100% !important;
    margin-bottom: 10px;
    font-size: 12px;
    height: 30px;
    line-height: 10px;
}

.media-list ul li .icon-checkbox {
    height: 20px;
    width: 20px;
    margin-top: -2px;
}

.media-list ul li .item-inner {
    font-size: 15px;
}

.media-list ul li ul li .icon-checkbox {
    height: 18px;
    width: 18px;
    margin-top: -5px;
}

.media-list ul li ul li .icon-checkbox:after {
    font-size: 16px;
    margin-top: -2px;
    margin-left: -2px;
}

.list .item-title {
    white-space: normal;
}

.swiper-container-benefits {
    overflow: hidden;
}

.swiper-container {
    /* max-height: 100% !important; */
    max-width: 100% !important;
    min-width: 100%;
    height: 100%;
}

.explainers button {
    margin-bottom: 5px;
}

.explainers a {
    margin-bottom: 10px;
}

.explainers-step .explainer-big {
    font-size: 19px;
}

.explainers-step .explainer-big i {
    color: black;
}

.expo {
    font-size: 10px;
}

.card-header-section-popup {
    background: #EEE;
    text-transform: uppercase;
    font-size: 11px;
    padding: 8px;
    background: #AD200F;
    color: white;
    font-weight: bold;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.block-list-box.block-popup .list {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
}

.block-list-box {
    margin-top: 10px;
    width: 88% !important;
}

.block-list-box .card-infos {
    padding: 0px 20px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: dotted 1px #CCC;
    background: #EEE;
}

.block-list-box .styled-form {
    background: none;
}

.block-list-box input,
.block-list-box select {
    background: white !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.block-list-box .item-content {
    padding-left: 0px;
}

.block-list-box .media-list ul {
    background: none;
}

.block-list-box .media-list ul li .icon-checkbox {
    background: white;
}

.apex_labels {
    width: 77%;
    margin: 0px auto;
    text-align: center;
    font-size: 14px;
    margin: 0px auto;
    text-align: center;
    color: black;
}

.apex_labels_round {
    width: 10px;
    height: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 6px;
}

#process-steps {
    text-align: center;
}

#process-steps img {
    width: 60%;
    margin: 0px auto;
}

#process-steps .process-title {
    font-size: 14px;
    color: black;
    margin-top: 10px;
    margin-bottom: 20px;
}

#process-steps img.calculator {
    width: 100%;
}

.card-100 {
    width: 100% !important;
}

.type-wrap-container-mini {
    position: fixed;
    bottom: 100px;
    z-index: 200000;
    right: 13px;
    display: none;
}

.type-wrap-container-mini i {
    float: right;
    font-size: 30px;
    color: #18b072 !important;
    margin-right: 17px;
    margin-top: 10px;
}

.type-wrap-container-mini .type-wrap-min {
    width: 40px;
    z-index: 19;
    text-align: left;
}

.type-wrap-container-active .type-wrap-min {
    width: 100%;
}

.type-wrap-container-mini .type-avatar-min {
    width: 50px;
    margin: auto;
    position: static;
    left: auto;
    z-index: 19;
    margin-left: 8px;
}

.type-wrap-container-mini.isTalking .type-wrap-min {
    width: 85%;
    margin: 0px auto;
}

.type-wrap-container-mini.isTalking {
    width: calc( 100% - 25px);
}

.isTalking .type-wrap.type-wrap-min:after {
    left: 86% !important;
}

.type-wrap.type-wrap-min .button {
    margin: 10px;
    color: white;
    font-size: 16px;
}

.type-wrap.type-wrap-min .avatarMsg {
    margin: 10px;
    font-size: 16px;
}

.type-wrap-container-mini.type-wrap-container-active .type-avatar-min,
.type-wrap-container-mini .type-avatar-min {
    margin-right: -10px;
    margin-left: auto;
    width: 73px;
    padding: 9px;
    border-radius: 27px;
}

.type-wrap-container-mini.type-wrap-container-active {
    width: calc( 90% - 20px);
    z-index: 19;
    text-align: center;
    left: 20px;
}

.sub_explainers_two_columns {
    padding: 10px;
}

#cashflow .block-for-cards-tiny .card-infos-grouped {
    padding: 10px 13px 1px;
}

#cashflow .block-for-cards-tiny * {
    font-size: 10px;
}

#cashflow .card {
    width: 100%;
}

#cashflow .block-for-cards {
    width: 96%;
}

#cashflow .up {
    top: 0px;
}

#cashflow .card-category {
    font-size: 12px;
}

#cashflow_filters .app_currency {}

#cashflow_filters .item-value-type {
    font-size: 11px;
    margin-top: -29px;
}

.events_title {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
}

.events_details {
    border-top: solid 1px #EEE;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 13px;
}

.events_item {
    width: calc( 97% - 20px) !important;
    margin: 0px auto;
    margin-bottom: 10px;
}

.events_description {
    font-size: 13px;
    margin-top: 31px;
    position: fixed;
    width: 100%;
    padding: 10px 0px;
    text-align: center;
}

.events_description_mini {
    font-size: 13px;
}

.title_subsection {
    padding: 10px 0px;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(239, 237, 237, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(239, 237, 237, 1) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(239, 237, 237, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#efeded", GradientType=1);
    text-align: center;
    position: fixed;
    width: 100%;
    top: 31px;
    font-size: 13px;
}

#view-starting-save {
    background: white;
}

.input-clear-button {
    display: none;
}

.stickyMainContainer .explainers {
    border: none;
    width: 95%;
}

.stickyMainContainer .explainer-slogan {
    text-align: center;
    padding: 10px;
    width: 100%;
    background: green;
    color: white;
}

.stickyMainContainer .explainers {
    margin-bottom: 60px;
}

.toolbar.toolbar-main i {
    color: #CCC;
}

.missing-error {
    border: solid 1px red !important;
}

.missing_field i {
    background: #f5cccc !important;
    border: solid 1px red !important;
}

.toast-icon i.fas,
.toast-icon i.fal,
.toast-icon i.fa {
    font-size: 70px;
    color: white;
}

.edit-listing-success,
.edit-listing-success i {
    color: #18b072 !important;
    font-weight: bold;
}

.edit-listing-error,
.edit-listing-error i {
    color: #dc3927 !important;
    font-weight: bold;
}

.edit-listing-success-gps,
.edit-listing-success-gps i,
.route-page .edit-listing-success-gps i {
    color: #0089ff !important;
    font-weight: bold;
}

.edit-listing-error-gps,
.edit-listing-error-gps i,
.route-page .edit-listing-error-gps i {
    color: #dc3927 !important;
    font-weight: bold;
}

.actions-group i {
    margin: 0px auto;
    font-size: 30px;
    color: #18b072 !important;
}

.actions-group .actions-button-text {
    color: black;
    font-weight: bold;
    margin-top: 0px;
    font-size: 15px;
}

.actions-grid .actions-button {
    width: 50% !important;
}

.setOptionDiv_disabled {
    display: none;
}

.button-green {
    background: #22b87b;
    color: white;
    font-size: 15px;
}

.button-green i {
    color: white !important;
}

.author-infos-checkbox,
.author-infos-name {
    flex: auto;
}

.author-infos-checkbox {
    padding: 10px 13px;
    margin: 0px !important;
}

.more_items_children .author-infos-checkbox,
.more_items_subchildren .author-infos-checkbox {
    padding: 0px 13px;
}

.author-infos-name {
    padding: 4px 0px;
    width: calc( 100% - 80px);
}

.author-infos-checkbox {
    width: 30px;
}

label.item-checkbox>.icon-checkbox {
    background: white;
}

.checkbox input[type=checkbox]:checked~i,
.checkbox input[type=checkbox]:indeterminate~i,
label.item-checkbox input[type=checkbox]:checked~* .icon-checkbox,
label.item-checkbox input[type=checkbox]:checked~.icon-checkbox,
label.item-checkbox input[type=checkbox]:indeterminate~* .icon-checkbox,
label.item-checkbox input[type=checkbox]:indeterminate~.icon-checkbox {
    background: #18b072;
    border: solid 1px green;
}

label.item-checkbox.active-state {
    background: none !important;
}

label.item-checkbox.active-state .icon {
    background: #EEE;
}

.button-checkbox-edit {
    display: none;
}

.item-input .item-inner {
    padding: 0px;
}

.item-listing-add i {
    font-size: 22px;
}

.item-listing-add .author-infos-checkbox {
    padding: 0px 19px;
    padding-top: 4px;
}

.item-listing-add .author-name {
    font-weight: bold;
    color: #18b072;
}

.item-listing-more {
    border-top: solid 1px #DDD;
}

.item-listing-more .edit-listing-label,
.item-listing-more .edit-listing-description {
    font-size: 13px;
}

.item-listing-more .author-name {
    margin-bottom: 0px;
}

.edit-listing-label-more {
    font-size: 14px;
    color: #AAA;
}

.item-listing-more .fa-times {
    color: red !important;
}

.more_items .item-listing-more {
    border-top: solid 1px #CCC;
    padding-left: 30px;
}

.more_subitems .item-listing-more {
    border-top: solid 1px #CCC;
    padding-left: 65px;
}

.item-listing-base-checked.item-listing-base-has-childs .author-infos-checkbox .checkbox i:after,
.item-listing-base-checked.item-listing-base-has-childs .author-infos-checkbox .icon-checkbox:after,
.item-listing-base-checked.item-listing-base-has-subchilds .author-infos-checkbox .checkbox i:after,
.item-listing-base-checked.item-listing-base-has-subchilds .author-infos-checkbox .icon-checkbox:after {
    content: normal;
}

.listing-with-subchildren .item-content.item-checkbox {
    padding: 7px;
}

.button-outline:hover {
    color: black !important;
}

.button-outline.button-active {
    background: #18b072;
    color: white;
}

.button-outline:hover.button-active {
    color: white !important;
}

.button-outline {
    color: black;
}

.item-value-type i {
    color: #18b072;
}

.type-wrap-mask {
    background: #ffffff;
    top: 0px;
    left: 0px;
    z-index: 20000;
    position: fixed;
    height: 100%;
    opacity: 0.5;
    width: 100%;
}

.swiper-wrapper p.row-no-padding {
    margin-bottom: 2px;
}

.swiper-slide i {
    color: #18b072;
}

.swiper-slide .button-green i {
    color: white !important;
}

.swiper-slide .button-active i {
    color: white !important;
}

.button-outline.button-conditions {
    background: #18b072;
    color: white;
}

.button-outline.button-conditions:hover {
    color: #18b072;
    background: white;
    border: solid 1px #18b072;
}

.item-value-type .fa {
    font-size: 20px;
}

h1.lowTopMargin {
    margin-top: 60px;
}

.avatar_selector {
    margin: 0px auto;
}

.avatar_selector_min .picture {
    height: 50px !important;
    width: 50px !important;
    float: left;
}

.avatar_selector .picture-container {
    position: relative;
    cursor: pointer;
    text-align: center;
}

.avatar_selector .picture {
    width: 250px;
    height: 250px;
    background-color: #999999;
    color: #FFFFFF;
    border-radius: 50%;
    margin: 5px auto;
    overflow: hidden;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
}

.avatar_selector .picture-src {
    width: 100%;
}


/*
* {
    transition: background 0.2s;
    -webkit-transition: background 0.2s;
    -moz-transition: background 0.2s;
    -o-transition: background 0.2s;	
}*/

.kv-upload-progress {
    border: solid 1px #EEE;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    display: none;
}

.progress-bar {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    background: #18b072;
    text-align: center;
    font-size: 12px;
    padding: 5px 0px;
    color: white;
}

.file-caption {
    display: none;
}

.btn-file input[type=file] {
    background: #EEE;
}

.fileinput-cancel {
    display: none;
}

.btn-file {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 0px;
    color: white;
    font-size: 20px;
    background: #18b072;
    text-align: center;
    display: block;
    margin-top: 20px;
    padding: 5px;
}

.block-menu-fixed>.row {
    width: 95%;
    margin: 0px auto;
}

.item-radio.item-content .item-media img {
    width: 30px;
}

.item-radio.item-content {
    margin-top: 0px;
}

.smart-select-sheet .list {
    margin-top: 0px;
}

.sheet-modal-inner ul {
    padding: 10px 0px;
}

.swiper-pagination-progressbar-fill {
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
}

#progressbar-wrap {
    height: 35px;
    display: none;
}

.calendar-month-selector *,
.current-month-value,
.calendar-year-selector * {
    color: white !important;
}

.calendar-day.calendar-day-selected .calendar-day-number {
    background-color: #e4f3e1;
    color: green;
    font-weight: bold;
}

#view-programs .list ul {
    background: none;
}

#view-programs .item-input-wrap {
    background: white;
}

.segmented-card {
    width: 100%;
    margin: 0px 0px 17px 0px !important;
}

.method_value {
    color: #22b87b;
    text-align: right;
    font-weight: bold;
}

.method_value_unit {
    text-align: right;
    font-size: 10px;
    color: #555;
}

#categoryMethodMessage {
    text-align: center;
    margin-top: 40px;
    font-size: 14px;
}

.farm_land_hectares {
    width: calc( 100% - 40px);
    text-align: center;
    background: #fff;
    padding: 10px;
}

#view-starting-conditions-general p,
#view-starting-conditions-privacy p {
    text-align: justify;
    font-size: 15px;
}

#view-starting-conditions-general h2,
#view-starting-conditions-privacy h2 {
    color: #18b072;
}

#privacy-content,
#contract-content {
    text-align: left;
    margin: 10px;
    margin-top: 60px;
    padding-bottom: 50px;
}

#privacy-content *,
#contract-content * {
    color: black;
}

#privacy-content h3,
#contract-content h3 {
    font-size: 16px;
}

#privacy-content li,
#contract-content li {
    text-align: justify;
}

#privacy-content li::marker,
#contract-content li::marker {
    color: #18b072;
    font-weight: bold;
}

#map_canvas_1,
#map_canvas_2 {
    border: solid 1px #AAA;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

#open-progress-perimeter-hand {
    background: #0089ff !important;
}

.farm_land_badge {
    width: 15px;
    height: 15px;
    display: block;
    float: left;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin-right: 7px;
    margin-top: 3px;
}

.farm_land_title {
    float: left;
    font-size: 15px;
    color: black;
}

.data_farm_lands_globals {
    width: 100%;
    padding: 5px 0px;
    clear: both;
}

#data_farm_lands_globals_all {
    margin-top: 5px;
}

.show_for_costarica,
.show_for_colombia {
    display: none;
}

.bluring {
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.pac-container {
    z-index: 200000000;
}

.button-map {
    background: white !important;
    color: black !important;
    padding: 0px 21px;
    margin: 0px;
    height: 16px !important;
}

.button-map i {
    color: black !important;
    margin-right: 5px;
}

.edit-listing-label-subdescription {
    font-size: auto;
    color: black;
}

#view-starting-personal {
    overflow-y: hidden !important;
}

.image-editor {
    margin: 0px auto;
}

#croppingTools a {
    width: 33.33%;
    float: left;
    border: solid 1px #EEE;
}

#croppingTools a:before {
    display: none !important;
}

.tipinside {
    border-top: solid 1px #AAA;
    background: #EEE;
    font-size: 12px;
    text-align: left;
    padding-left: 14px;
    overflow: hidden;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 15px;
}

.tipinside i {
    color: #6f6f6f !important;
}

.modal-in ::-webkit-scrollbar,
#view-starting-conditions-privacy::-webkit-scrollbar,
#view-starting-conditions-general::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    color: #18b072;
}

.modal-in ::-webkit-scrollbar-track,
#view-starting-conditions-privacy::-webkit-scrollbar-track,
#view-starting-conditions-general::-webkit-scrollbar-track {
    border-radius: 10px;
}

.modal-in ::-webkit-scrollbar-thumb,
#view-starting-conditions-privacy::-webkit-scrollbar-thumb,
#view-starting-conditions-general::-webkit-scrollbar-thumb {
    background-color: #18b072;
    margin-right: 3px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 1px #18b072;
    box-shadow: 0 0 1px #18b072;
}

.dialog-inner .item-value-type {
    margin-top: -27px;
    margin-right: 15px;
}

.illustrations {
    margin-top: 10px;
    margin-bottom: 10px;
}

.illustrations>div {
    height: 40px;
}

.illustration {
    text-align: center;
    height: 60px;
    display: none;
}

.illustration_b {
    margin-top: 8px;
}

.illustration img {
    height: 100%;
    margin: 0px auto;
    text-align: center;
}

.illustration i {
    font-size: 50px;
}

.illustration.illustration-arrow-right i {
    color: #18b072;
}

.illustration.illustration-arrow-left i {
    color: #a91907 !important;
}

.type-wrap-container-mini .illustrations i {
    margin: 0px auto !important;
    float: none !important;
}

.route-page #privacy-content,
.route-page #contract-content {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
}

.toolbar-hidden {
    transform: none !important;
}

.list-block-no-margin {
    padding-left: 0px;
}

option.optionCapitalize {
    text-transform: initial;
}